
import { defineComponent, ref } from 'vue';
import GridLine from '@/components/GridLine.vue';

export default defineComponent({
  name: 'Header',
  components: {
    GridLine,
  },
  setup() {
    const menuOpen = ref(false);
    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
    };

    return {
      menuOpen,
      toggleMenu,
    };
  },
});
