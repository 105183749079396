import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue'),
      },
      {
        path: 'solutions',
        name: 'Solutions',
        component: () => import(/* webpackChunkName: "solution" */ '@/pages/Solutions.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '@/pages/Products.vue'),
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "company" */ '@/pages/Company.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/pages/Contact.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '@/pages/PrivacyPolicy.vue'),
      },
      {
        path: '/:catchAll(.*)*',
        component: () => import(/* webpackChunkName: "error404" */ '@/pages/Error404.vue'),
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
